import { styled, Typography } from '@material-ui/core';

export const Heading = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontFamily: 'Source Sans Pro',
  fontWeight: 700,
  fontSize: '2.5rem',
  color: theme.palette.grey[800],
  lineHeight: 2,
  '& > sup': {
    fontWeight: 400,
    fontSize: '1.5rem',
    color: theme.palette.grey[500],
  },
  '& > em': {
    fontStyle: 'normal',
    color: theme.palette.grey[500],
  },
}));

Heading.defaultProps = {
  variant: 'h3',
  gutterBottom: true,
};
