import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { SitePage } from 'components/layout';
import { ExtLink, CredibleResources } from 'components';
import { Heading } from '../styles';
import anxiety_guideline from '../assets/anxiety_self-help_guidelines.pdf';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    title: {
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
    heading: {
      fontSize: '2rem',
      fontWeight: 500,
      color: '#37828d',
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: 1,
      },
    },
  }),
);

export default function index() {
  const classes = useStyles();
  const bullyingSection = (
    <div>
      <Typography variant="h5" align="left" className={classes.title}>
        Bullying. No Way!
      </Typography>

      <Typography paragraph>
        <ExtLink href="https://bullyingnoway.gov.au">
          This Australian resource provides information and helpful ideas about bullying for parents and teenagers.
        </ExtLink>
      </Typography>

      <Typography variant="h5" align="left" className={classes.title}>
        Cyber-bullying information
      </Typography>

      <Typography component="span" paragraph>
        Adapted from: Australian Government eSafety Commissioner. See information for parents{' '}
        <ExtLink href="https://www.esafety.gov.au/parents">here.</ExtLink>
        <br />
        <br />
        For more information on cyber-safety, check out:
        <ul>
          <li>
            <ExtLink href="https://www.ideas.org.au/uploads/events/333/Parenting%20online.pdf">
              Parents guide to online safety
            </ExtLink>
            .
          </li>
          <li>
            <ExtLink href="https://www.childnet.com/parents-and-carers">Childnet international.</ExtLink>
          </li>
        </ul>
      </Typography>

      <Typography variant="h5" align="left" className={classes.title}>
        <ExtLink href="https://www.esafety.gov.au/diverse-groups/lgbtiq">LGBTQIA+ information</ExtLink>
      </Typography>

      <Typography component="span" paragraph>
        Adapted from:{' '}
        <ExtLink href="http://familieslikemine.beyondblue.org.au/#folio=56">
          beyondblue. (n.d.) <b>Families like mine.</b>
        </ExtLink>
        <br />
        <br />
        <Typography paragraph>
          Other resources to support LGBTQIA+ young people:
          <br />
        </Typography>
        <ul>
          <li>
            <ExtLink href="https://headspace.org.au/friends-and-family/understanding-gender-identity-for-families/">
              Headspace resource: Understanding gender identity – for family and friends.
            </ExtLink>
          </li>
          <li>
            <ExtLink href="https://headspace.org.au/friends-and-family/understanding-sexuality-and-sexual-identity-for-family-and-friends/">
              Headspace resource: Understanding sexuality and sexual identity – for family and friends.
            </ExtLink>
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" align="left" className={classes.title}>
        Helping your teenager navigate problems with social rejection and bullying
      </Typography>

      <Typography component="span" paragraph>
        Reference:
        <ol>
          <li>
            <ExtLink href="https://www.dese.gov.au/student-resilience-and-wellbeing/resources/australian-covert-bullying-prevalence-study-executive-summary">
              Cross, D., Shaw, T., Hearn, L., Epstein, M., Monks, H., Lester, L., & Thomas, L. (2009).{' '}
              <b>Australian Covert Bullying Prevalence Study (ACBPS).</b> Edith Cowan University, Perth: Child Health
              Promotion Research Centre.
            </ExtLink>
          </li>
        </ol>
      </Typography>
    </div>
  );
  return (
    <SitePage>
      <Container className={classes.root}>
        <Typography color="primary" variant="h4" align="left" className={classes.title}>
          Appendices
        </Typography>

        <Typography paragraph>
          Here is a list of appendices for some of the modules in the <i>Partners in Parenting program</i>. Where
          available, we have provided a direct link to the resources or references within each module. Feel free to
          check out these resources by clicking on them.
        </Typography>

        <Heading className={classes.heading}>
          Module: Parenting through the pandemic: Navigating the ‘new normal’
        </Heading>
        <Typography component="span" paragraph>
          Guidelines: Self help strategies for mild anxiety (
          <ExtLink href={anxiety_guideline}>click here to download a pdf</ExtLink>).
          <br />
          <br />
        </Typography>

        <CredibleResources subheading={false} />

        <Typography component="span" paragraph>
          Reference:
          <ol>
            <li>
              <ExtLink href="https://www.cambridge.org/core/journals/bjpsych-open/article/interpersonal-violence-and-mental-health-outcomes-following-disaster/FE519D59C68BCE944AE4A88884A157C5">
                Molyneaux, R., Gibbs, L., Bryant, R., Humphreys, C., Hegarty, K., & Kellett, C. et al. (2019).{' '}
                <b>Interpersonal violence and mental health outcomes following disaster.</b> BJPsych Open, 6(1).
              </ExtLink>
              <li>
                <ExtLink href="https://journals.sagepub.com/doi/10.1177/1524838019835973">
                  Seddighi, H., Salmani, I., Javadi, M., & Seddighi, S. (2019).{' '}
                  <b>Child abuse in natural disasters and conflicts: A systematic review.</b>
                  Trauma, Violence, & Abuse, 22(1), 176-185.
                </ExtLink>
              </li>
            </li>
          </ol>
        </Typography>

        <Heading className={classes.heading}>Module: Nurture roots & inspire wings</Heading>

        <Typography component="span" paragraph>
          <br />
          Reference:
          <ol>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/24308895">
                Yap, M. B. H., Pilkington, P. D., Ryan, S. M., & Jorm, A. F. (2014).{' '}
                <b>
                  Parental factors associated with depression and anxiety in young people: A systematic review and
                  meta-analysis.{' '}
                </b>
                Journal of Affective Disorders, 156, 8-23.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Heading className={classes.heading}>Module: Good friends = supportive relationships</Heading>

        {bullyingSection}

        <Heading className={classes.heading}>Module: Good health habits for good mental health</Heading>

        <Typography variant="h5" align="left" className={classes.title}>
          Encourage a healthy diet
        </Typography>

        <Typography paragraph>
          For information on the Australian Guide to Healthy Eating, click{' '}
          <ExtLink href="https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating">here.</ExtLink>
        </Typography>

        <Typography paragraph>
          You can also download a copy of the guide{' '}
          <ExtLink href="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55_agthe_large.pdf">
            here.
          </ExtLink>
        </Typography>

        <Typography paragraph>
          For information about serving sizes and how much your teenager should eat each day, click{' '}
          <ExtLink href="https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day">here.</ExtLink>
        </Typography>

        <Typography component="span" paragraph>
          <br />
          References:
          <ol>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/20048020">
                Jacka, F. N., Pasco, J. A., Mykletun, A., Williams, L. J., Hodge, A. M., O’Reilly, S. L., . . .Berk, M.
                (2010b). <b>Association of western and traditional diets with depression and anxiety in women.</b>{' '}
                American Journal of Psychiatry, 167(3), 305-311.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="https://www.abs.gov.au/ausstats/abs@.nsf/Lookup/by%20Subject/4338.0~2011-13~Main%20Features~Children's%20risk%20factors~10010">
                Australian Bureau of Statistics. <b>Profiles of Health Australia, 2011-2012.</b>
              </ExtLink>
            </li>
            <li>
              <ExtLink href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5115694/pdf/ahmt-7-125.pdf">
                Rankin et al. (2016):{' '}
                <b>Psychological consequences of childhood obesity: Psychiatric comorbidity and prevention.</b>{' '}
                Adolescent Health, Medicine and Therapeutics, 7, 125-146.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Encourage daily exercise
        </Typography>

        <Typography component="span" paragraph>
          References:
          <ol start={4}>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/23648221">
                Kremer, P., Elshaug, C., Leslie, E., Toumbourou, J. W., Patton, G. C., & Williams, J. (2014).{' '}
                <b>Physical activity, leisure-time screen use and depression among children and young adolescents</b>{' '}
                Journal of Science and Medicine in Sport, 17(2), 183-187.
              </ExtLink>
            </li>
          </ol>
          <ExtLink href="https://www.health.gov.au/sites/default/files/documents/2021/05/24-hour-movement-guidelines-children-and-young-people-5-to-17-years-fact-sheet.pdf">
            Click here{' '}
          </ExtLink>
          to see the physical activity guidelines for children and young people.
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Encourage healthy screen use
        </Typography>

        <Typography paragraph>
          <ExtLink href="https://www.internetmatters.org/issues/screen-time/?gclid=EAIaIQobChMI9oSvr-Ox5QIVDLTtCh35gwTgEAAYASAAEgL_8fD_BwE">
            Click here
          </ExtLink>{' '}
          for more information and tips for managing screen time.
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Encourage good sleep habits
        </Typography>

        <Typography component="span" paragraph>
          References:
          <ol start={5}>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/23415826">
                Lopresti, A. L., Hood, S. D., & Drummond, P. D. (2013).{' '}
                <b>
                  A review of lifestyle factors that contribute to important pathways associated with major depression:
                  Diet, sleep and exercise.
                </b>{' '}
                Journal of Affective Disorders, 148(1), 12-27.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/21300408">
                Baglioni, C., Battagliese, G., Feige, B., Spiegelhalder, K., Nissen, C., Voderholzer, U., Lombardo, C.,
                & Riemann, D. (2011).{' '}
                <b>
                  Insomnia as a predictor of depression: a meta-analytic evaluation of longitudinal epidemiological
                  studies.
                </b>{' '}
                Journal of Affective Disorders, 135(1-3), 10-19.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="http://vuir.vu.edu.au/467/1/teenagesleep.pdf">
                Bruck, D. (2006). <b>Teenage Sleep: Understanding and helping the sleep of 12-20 year olds.</b> Wellness
                Promotion Unit, Victoria University, Melbourne, Australia.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Ensure no alcohol or drug use
        </Typography>

        <Typography component="span" paragraph>
          <ol start={8}>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/21806515">
                McKenzie, M., Jorm, A. F., Romaniuk, H., Olsson, C. A., Jorm, A. F., Romaniuk, H., & Patton, G. C.
                (2011).{' '}
                <b>
                  Association of adolescent symptoms of depression and anxiety with alcohol use disorders in young
                  adulthood: Findings from the Victorian Adolescent Health Cohort Study.
                </b>{' '}
                Medical Journal of Australia, 195, S27-30.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/20707783">
                McKenzie, M., Olsson, C. A., Jorm, A. F., Romaniuk, H., & Patton, G. C. (2010).{' '}
                <b>
                  Association of adolescent symptoms of depression and anxiety with daily smoking and nicotine
                  dependence in young adulthood: Findings from a 10-year longitudinal study.
                </b>{' '}
                Addiction, 105 (9), 1652-1569.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="https://journals.sagepub.com/doi/10.1080/00048670701634986">
                Baker, K. D., Lubman, D. I., Cosgrave, E. M., Killackey, E. J., Yuen, H. P., Hides, L., Baksheev, G.N.,
                Buckby, J.A., & Yung, A. R. (2007).{' '}
                <b>
                  Impact of co-occurring substance use on 6 month outcomes for young people seeking mental health
                  treatment.
                </b>{' '}
                Australia New Zealand Journal of Psychiatry, 41 (11), 896-902.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Parenting strategies: Preventing adolescent alcohol misuse
        </Typography>

        <Typography paragraph>
          You can find out more about parenting strategies to prevent alcohol misuse in teenagers{' '}
          <ExtLink href="https://www.parentingstrategies.net">here.</ExtLink>
        </Typography>

        <Heading className={classes.heading}>Module: Partners in problem solving</Heading>

        <Typography variant="h5" align="left" className={classes.title}>
          Stress management source:
        </Typography>

        <Typography paragraph>
          <ExtLink href="https://www.jhsph.edu/research/centers-and-institutes/center-for-adolescent-health/_docs/TTYE-Guide.pdf">
            McNeely, C. & Blanchard, J. (2009).
            <b>The Teen Years Explained: A Guide to Healthy Adolescent Development.</b> Baltimore: Center for Adolescent
            Health. Johns Hopkins Bloomberg School of Public Health.
          </ExtLink>
        </Typography>

        {bullyingSection}

        <Heading className={classes.heading}>
          Module: From surviving to thriving: Helping your teenager deal with anxiety
        </Heading>

        <Typography component="span" paragraph>
          Reference:
          <ol>
            <li>
              <ExtLink href="https://www.abs.gov.au/statistics/health/mental-health/national-survey-mental-health-and-wellbeing-summary-results/latest-release">
                Australian Bureau of Statistics. (2008).{' '}
                <b>ABS National Survey of Mental Health and Wellbeing: Summary of Results 2007</b>. Canberra, Australia:
                ABS.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="https://www.oxfordclinicalpsych.com/view/10.1093/med-psych/9780190869984.001.0001/med-9780190869984">
                Lebowitz, E. R. (2019). <b>Addressing parental accommodation when treating anxiety in children</b>. New
                York: Oxford University Press.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Heading className={classes.heading}>Module: When things aren’t okay: Getting professional help</Heading>

        <Typography variant="h5" align="left" className={classes.title}>
          Mental Health First Aid (MHFA) guidelines on depression
        </Typography>

        <Typography paragraph>
          For more information on what to do to help someone with depression,{' '}
          <ExtLink href="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_depression_guidelines_A4_2012.pdf">
            download the MHFA guidelines on depression.
          </ExtLink>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          Mental Health First Aid guidelines on clinical anxiety
        </Typography>

        <Typography component="span" paragraph>
          For more information on what to do to help someone with clinical anxiety, download the MHFA guidelines on
          traumatic events and panic attacks:
          <ul>
            <li>
              <ExtLink href="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_child_guidelines_A4_2012_1.pdf">
                Mental Health First Aid Guidelines on helping a child after a traumatic event.
              </ExtLink>
            </li>
            <li>
              <ExtLink href="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_panic_guidelines_A4_2012.pdf">
                Mental Health First Aid guidelines on panic attacks.
              </ExtLink>
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          But what if my teenager doesn’t want to seek help?
        </Typography>

        <Typography component="span" paragraph>
          Reference:
          <ol>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/23228570">
                Yap MB Reavley N, Jorm AF (2013).{' '}
                <b>
                  Where would young people seek help for mental disorders and what stops them? Findings from an
                  Australian national survey.
                </b>{' '}
                Journal of Affective Disorders, 147(1-3):255-61.
              </ExtLink>
            </li>
          </ol>
        </Typography>

        <Typography variant="h5" align="left" className={classes.title}>
          What if my teenager has previously had depression?
        </Typography>

        <Typography component="span" paragraph>
          Reference:
          <ol start={2}>
            <li>
              <ExtLink href="http://www.ncbi.nlm.nih.gov/pubmed/18799220">
                Morgan, A. J., & Jorm, A. F. (2009).{' '}
                <b>Self-help strategies that are helpful for sub-threshold depression: A Delphi consensus study.</b>{' '}
                Journal of Affective Disorders, 115(1), 196–200.
              </ExtLink>
            </li>
          </ol>
        </Typography>
      </Container>
    </SitePage>
  );
}
